<template>
  <div class="dimbox register">
    <div class="mHeader">
      <div class="flexL tit">
        <p class="bold">웨딩 컬렉션 등록</p>
      </div>
      <button @click="close">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="mBody">
      <div class="flexL">
        <p class="bold">
          갤러리 제목
          <span>*</span>
        </p>
        <input type="text" v-model="title" />
      </div>
      <div class="flexL">
        <p class="bold">
          갤러리 사진
          <span>*</span>
        </p>
        <div class="upload">
          <div class="flexB">
            <div class="filebox">
              <label for="photos">찾아보기</label>
              <input type="file" ref="photos" id="photos" @change="sendFile('photos')" accept="image/*" multiple />
            </div>
          </div>
          <div class="flex images" v-if="photos.length > 0">
            <div v-for="(image, i) in photos" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(image)">
                <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="buttonWrap">
        <button @click="close" class="close">취소</button>
        <button class="basic" @click="updateData">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile, deleteFile } from "@/api/file";
export default {
  props: ["select"],
  data() {
    return {
      editMode: false,
      title: "",
      photos: [],
      file: "",
    };
  },

  mounted() {
    if (this.select != null) {
      this.editMode = true;
      this.title = this.select.title;
      this.photos = this.select.images;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateData() {
      if (this.title == "") {
        this.$alert("갤러리 제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.photos.length == 0) {
        this.$alert("갤러리 사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      let data = {
        title: this.title,
        images: this.photos,
      };
      this.$emit("updateData", data);
      this.$emit("close");
    },
    sendFile() {
      if (this.file == undefined) {
        return;
      }
      this.file = this.$refs.photos.files[0];
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.photos.push(res.data.url);
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            this.photos.splice(this.photos.indexOf(url), 1);
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
  },
};
</script>
