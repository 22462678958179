<template>
  <div class="dimbox register">
    <div class="mHeader">
      <div class="flexL tit">
        <p class="bold">웨딩 컬렉션 등록</p>
      </div>
      <button @click="close">
        <span class="material-icons">close</span>
      </button>
    </div>
    <div class="mBody">
      <div class="flexL">
        <p class="bold">
          상품정보 제목
          <span>*</span>
        </p>
        <el-select v-model="title" placeholder="선택" class="searchCondition">
          <el-option
            key="상품 구성"
            label="상품 구성"
            value="상품 구성"
          ></el-option>
          <el-option
            key="추가 사항"
            label="추가 사항"
            value="추가 사항"
          ></el-option>
          <el-option
            key="혜택 쿠폰"
            label="혜택 쿠폰"
            value="혜택 쿠폰"
          ></el-option>
          <el-option key="기타" label="기타" value="기타"></el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="title.includes('상품 구성')">
        <p class="bold">
          상품 정보
          <span>*</span>
        </p>
        <textarea v-model="content" />
      </div>
      <div class="flexL" v-if="title.includes('상품 구성')">
        <p class="bold">가격</p>
        <input type="text" v-model="price" />
      </div>
      <div class="flexL" v-if="title.includes('상품 구성')">
        <p class="bold">공급가(VAT 제외)</p>
        <input type="text" v-model="supplyPrice" />
      </div>
      <div class="flexL" v-if="title.includes('상품 구성')">
        <p class="bold">리베이트</p>
        <input type="text" v-model="rebate" />
      </div>
      <div class="flexL" v-if="title.includes('추가 사항')">
        <p class="bold">추가 사항 내용 <span>*</span></p>
        <textarea v-model="addition" />
      </div>
      <div class="flexL" v-if="title.includes('혜택 쿠폰')">
        <p class="bold">참고 이미지</p>
        <div class="imgBox">
          <ul>
            <li v-if="thumbnail">
              <img :src="thumbnail" alt="업로드이미지" />
              <a class="cancel" @click="handleDeleteFile(thumbnail)">
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </li>
            <li v-else>
              <label for="imgUpload">
                <span class="material-icons addBtn">add</span>
              </label>
              <input
                type="file"
                ref="thumbnail"
                @change="sendFile('thumbnail')"
                id="imgUpload"
                accept="image/*"
              />
            </li>
          </ul>
        </div>
        <!-- <div class="upload">
          <div class="flexB">
            <div class="filebox">
              <label for="images">찾아보기</label>
              <input
                type="file"
                ref="images"
                id="images"
                @change="sendFile('images')"
                accept="image/*"
                multiple
              />
            </div>
          </div>
          <div class="flex images" v-if="images.length > 0">
            <div v-for="(image, i) in images" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(image, i)">
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </div>
          </div>
        </div> -->
      </div>
      <div class="flexL" v-if="title.includes('혜택 쿠폰')">
        <p class="bold">혜택 제목 <span>*</span></p>
        <input type="text" v-model="service" />
      </div>
      <div class="flexL" v-if="title.includes('혜택 쿠폰')">
        <p class="bold">혜택 적용 내용</p>
        <input type="text" v-model="svcContents" />
      </div>
      <div class="flexL" v-if="title.includes('혜택 쿠폰')">
        <p class="bold">혜택 적용 기간</p>
        <el-date-picker
          v-model="applyDate"
          type="daterange"
          range-separator="To"
          start-placeholder="시작 날짜"
          end-placeholder="끝 날짜"
        ></el-date-picker>
      </div>
      <div class="flexL" v-if="title.includes('기타')">
        <p class="bold">기타 내용 <span>*</span></p>
        <textarea v-model="etc" />
      </div>
      <div class="buttonWrap">
        <button @click="close" class="close">취소</button>
        <button class="basic" @click="updateData">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile, deleteFile } from "@/api/file";
export default {
  props: ["select"],
  data() {
    return {
      file: "",
      editMode: false,
      title: "",
      content: "",
      price: "",
      supplyPrice: "",
      rebate: "",
      addition: "",
      thumbnail: "",
      service: "",
      svcContents: "",
      applyDate: "",
      etc: "",
    };
  },
  mounted() {
    if (this.select != null) {
      this.editMode = true;
      this.title = this.select.title;
      this.content = this.select.content;
      this.price = this.select.price;
      this.supplyPrice = this.select.supplyPrice;
      this.rebate = this.select.rebate;
      this.addition = this.select.addition;
      this.thumbnail = this.select.thumbnail;
      this.service = this.select.service;
      this.svcContents = this.select.svcContents;
      this.applyDate = this.select.applyDate;
      this.etc = this.select.etc;
    } else {
      this.editMode = false;
      this.title = "";
      this.content = "";
      this.price = "";
      this.supplyPrice = "";
      this.rebate = "";
      this.addition = "";
      this.thumbnail = "";
      this.service = "";
      this.svcContents = "";
      this.applyDate = "";
      this.etc = "";
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateData() {
      if (this.title == "") {
        this.$alert("상품정보 제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      if (this.title == "상품 구성") {
        if (this.content === "") {
          this.$alert("상품 정보를 입력해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        } else {
          this.addition = "";
          this.thumbnail = "";
          this.service = "";
          this.svcContents = "";
          this.applyDate = "";
          this.etc = "";
        }
      } else if (this.title == "추가 사항") {
        if (this.addition === "") {
          this.$alert("추가 사항 내용을 입력해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        } else {
          this.content = "";
          this.price = "";
          this.supplyPrice = "";
          this.rebate = "";
          this.thumbnail = "";
          this.service = "";
          this.svcContents = "";
          this.applyDate = "";
          this.etc = "";
        }
      } else if (this.title == "혜택 쿠폰") {
        if (this.service === "") {
          this.$alert("혜택 제목을 입력해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        } else {
          this.content = "";
          this.price = "";
          this.supplyPrice = "";
          this.rebate = "";
          this.addition = "";
          this.etc = "";
        }
      } else if (this.title == "기타") {
        if (this.etc === "") {
          this.$alert("기타 내용을 입력해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        } else {
          this.content = "";
          this.price = "";
          this.supplyPrice = "";
          this.rebate = "";
          this.addition = "";
          this.thumbnail = "";
          this.service = "";
          this.svcContents = "";
          this.applyDate = "";
        }
      }
      let data = {
        title: this.title,
        content: this.content,
        price: this.price,
        supplyPrice: this.supplyPrice,
        rebate: this.rebate,
        addition: this.addition,
        thumbnail: this.thumbnail,
        service: this.service,
        svcContents: this.svcContents,
        applyDate: this.applyDate,
        etc: this.etc,
      };
      // this.title = "";
      // this.content = "";
      // this.etc = "";
      // this.price = "";
      // this.supplyPrice = "";
      // this.rebate = "";
      // this.service = "";
      // this.svcContents = "";
      // this.applyDate = "";
      // this.thumbnail = "";
      // this.etc = "";
      this.$emit("updateData", data);
      this.$emit("close");
    },
    sendFile() {
      if (this.thumbnail) {
        this.$alert("썸네일 삭제 후 업로드 해주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.file = this.$refs.thumbnail.files[0];
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);
      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.thumbnail = res.data.url;
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            this.thumbnail = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
  },
};
</script>
